import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    name: "",
    // hidden: true,
    children: [
      {
        path: "/",
        name: "Dashboard",
        meta: { title: "Dashboard", icon: "dashboard", affix: true },
        component: () => import("@/views/dashboard/index")
      }
    ]
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index")
      }
    ]
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index")
      }
    ]
  }
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  // mode: "history",
  mode: "hash",
  routes: constantRouterMap
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "User",
    meta: { title: "Customers", permissions: ["user"], icon: "users" },
    children: [
      {
        path: "/user/list",
        name: "CustomersList",
        component: () => import("@/views/user/index"),
        meta: { title: "Customers List", permissions: ["user"] }
      },
      {
        path: "/user/affiliateProgram",
        name: "AffiliateProgram",
        component: () => import("@/views/user/affiliateProgram"),
        meta: { title: "Affiliate Program", permissions: ["user"] }
      },
    ]
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "Report",
    meta: { title: "Reports", permissions: ["user"], icon: "form" },
    children: [
      {
        path: "/report/referral_tree",
        name: "ReferralTree",
        component: () => import("@/views/report/referral_tree"),
        meta: { title: "Referral Tree", permissions: ["user"] }
      },
      {
        path: "/report/wallet_balance",
        name: "WalletBalance",
        component: () => import("@/views/report/wallet_balance"),
        meta: { title: "Wallet Balance", permissions: ["user"] }
      },
      {
        path: "/report/reward_allocation",
        name: "RewardAllocation",
        component: () => import("@/views/report/reward_allocation"),
        meta: { title: "Reward Allocation", permissions: ["user"] }
      },
      {
        path: "/report/deposit",
        name: "Deposit",
        component: () => import("@/views/report/deposit"),
        meta: { title: "Deposit", permissions: ["user"] }
      },
      {
        path: "/report/bybit_trade",
        name: "BybitTrade",
        component: () => import("@/views/report/bybit_trade"),
        meta: { title: "Bybit Trade", permissions: ["user"] }
      },
      {
        path: "/report/balance_journal",
        name: "BalanceJournal",
        component: () => import("@/views/report/balance_journal"),
        meta: { title: "Balance Journal", permissions: ["user"] }
      },
    ]
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "Settings",
    meta: { title: "Settings", permissions: ["user"], icon: "system" },
    children: [
      {
        path: "/settings/distribution_test",
        name: "DistributionTest",
        component: () => import("@/views/settings/distribution_test"),
        meta: { title: "Distribution Test", permissions: ["user"] }
      },
    ]
  },
  { path: "*", redirect: "/error/404", hidden: true }
];
